<template>
    <div class="box">
        <div class="headerBack">
            <img src="https://q-res.oss-cn-beijing.aliyuncs.com/qingwa-index/%E5%85%AC%E5%8F%B8%E4%BB%8B%E7%BB%8D.png"
                 alt=""/>
        </div>
        <div class="content">
            <div class="title">
                海南青蛙传媒科技有限公司
            </div>
            <div class="minTitle"></div>
            <div class="contentText">
                <p>海南青蛙传媒科技有限公司成立于2020年，是一家基于区块链技术、版权保护技术、大数据技术应用于互联网场景的技术型服务公司。</p>

                <p>公司成立伊始，致力于打破影视版权发行、渠道合作的传统限制壁垒，依托强大的技术基础和资源整合能力，为版权方提 供版权保护服务、版权商业运营服务，同时也为渠道方提供更为便捷、透明的版权获取及使用途径。始终秉承以需求为导 向，解决影视发行版权找渠道、渠道求版权资源的繁琐而漫长的过程，让版权方与渠道方的资源互利共赢变得高效、便捷 ，为影视行业提供一个公平、公开、透明、可信的综合服务发行平台。</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "problem"
}
</script>

<style scoped>
.headerBack {
    height: 3rem;
    width: 100%;
}

.headerBack > img {
    width: 100%;
    height: 100%;
}

.title {
    font-size: .12rem;
    font-weight: 600;
    color: #000000;
    height: .23rem;
    position: relative;
    margin-top: .3rem;
}

.title:after {
    content: '';
    width: .6rem;
    height: .03rem;
    background: #00c2af;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: .015rem;
}

.content {
    width: 7rem;
    margin: 0 auto;
}

.textStyle {
    text-indent: .1rem;
    padding-left: .1rem;
}

.contentText {
    padding-top: .18rem;
    font-size: .1rem;
    padding-bottom: .2rem;
}

.minTitle{
    font-size: .08rem;
    padding-top: .06rem;
}
</style>