<template>
    <div class="box">
        <div class="headerBack">
            <img src="https://q-res.oss-cn-beijing.aliyuncs.com/qingwa-index/%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98.png"
                 alt=""/>
        </div>
        <div class="content">
            <div class="title">
                常见问题
            </div>
            <div class="contentText">
                <p><b>1.如何入驻青蛙影视区块链发布平台？</b></p>

                <p class="textStyle">1.<b>如果您是版权方</b>，在网站顶部点击“版权方入驻”进入，进行入驻申请填写，并认真且真实填写机构名称、机构证件代码、联系人姓名、联系方式、联系邮箱等相关企业及联系人信息，同时需提供《广播电视节目制作经营许可证》、《企业年检报告》、《三合一营业执照》等相关企业资质材料；
                </p>

                <p class="textStyle">2.<b>如果您渠道方</b>，在网站顶部点击“渠道方入驻”进入，进行入驻申请填写，并认真且真实填写机构名称、机构证件代码、联系人姓名、联系方式、联系邮箱等相关企业及联系人信息，同时需提供《企业年检报告》、《三合一营业执照》、渠道相关主题资质等相关企业资质材料；
                </p>

                <p><b>2.资质审核需要多久？</b></p>

                <p class="textStyle">提交入驻申请后，平台会在72小时内进行审核，审核通过后平台会与您取得联系，请保持提交入驻申请时填写的联系方式保持畅通。</p>

                <p><b>3.入驻申请被驳回怎么办？</b></p>

                <p class="textStyle">提交入驻申请后，平台会在72小时内进行审核，审核失败平台会与您取得联系，并协助您完成入驻申请，请保持提交入驻申请时填写的联系方式保持畅通。</p>

                <p>4.入驻成功后，我可以做什么？</p>

                <p class="textStyle">（1）<b>如果您是版权方</b>，入驻成功后登录平台系统，可以发布节目以及节目的视频资源，节目分为电影、剧集，可设定编辑节目相关信息，同时设置节目的价格；您的节目发布后，渠道方会在节目市场中看到您发布的节目资源，通过使用您发布的资源会产生流量费用，即您的节目产生收益。
                </p>

                <p class="textStyle">（2）<b>如果您是渠道方</b>，入驻成功登录平台系统，可以通过节目市场，查找您需要的节目版权资源，通过开发接口或购买版权使用权，可在您的渠道使用或分享版权资源。</p>

                <p><b>5.收益如何结算（版权方）？</b></p>

                <p class="textStyle">
                    每月1日，即为月结账日，届时可在结算中心看到月结情况。目前我平台以公对公付款方式，渠道方在结清节目使用费用后，平台收到相应款项会第一时间对公打款至您的企业对公账户内，请及时跟踪月结单结算状态，并进行对公账户明细查账。</p>

                <p><b>6.什么是逾期制约（渠道方）？</b></p>

                <p class="textStyle">
                    月结单产生后，根据月结单的费用进行付款，付款期设定为每月的1日至10日，请在付款期内进行版权使用费支付，11日进入逾期阶段，平台根据您的付款状态及时效，对您的账户进行版权限制及部分功能的使用，并对您的账户进行重新评估，如出现多次未付款逾期情况，平台有权对您的账号全面禁用。</p>

                <p><b>7.如何了解收益的具体来源及明细（版权方）？</b></p>

                <p class="textStyle">
                    平台采用区块链技术，您的节目发布上架后，你的节目产生的所有使用情况，在区块链有完整的明细，同时在结算中心可查看到节目的被购买情况，包括购买渠道、时间及点播情况等，平台保证所有数据的真实性，且区块链信息是完全公开公正的，没有任何隐藏规则限制。</p>

                <p><b>8.节目发布流程是什么样？</b></p>

                <p>（1）发布节目：创建节目，包括节目版权、形式、分账情况及介绍等基本信息等；</p>
                <p>（2）节目审核：对所创建节目进行内容审核；</p>
                <p>（3）上传视频：将视频上传至服务器；</p>
                <p>（4）视频审核：转码完成后视频进入审核阶段；</p>
                <p>（5）节目发布：审核通过，节目正式上架节目市场。</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "problem"
}
</script>

<style scoped>
.headerBack {
    height: 2.5rem;
    width: 100%;
}

.headerBack > img {
    width: 100%;
    height: 100%;
}

.title {
    font-size: .12rem;
    font-weight: 600;
    color: #000000;
    height: .23rem;
    position: relative;
    margin-top: .3rem;
}

.title:after {
    content: '';
    width: .6rem;
    height: .03rem;
    background: #00c2af;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: .015rem;
}

.content {
    width: 7rem;
    margin: 0 auto;
}

.textStyle {
    text-indent: .1rem;
    padding-left: .1rem;
}

.contentText{
    padding-top: .18rem;
    font-size: .1rem;
    padding-bottom: .2rem;
}
</style>