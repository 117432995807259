<template>
  <div id="box channelBox">
    <div class="copyright-top">
      <img
        src="https://r2.qingwashipin.com/qingwa-index/%E6%B8%A0%E9%81%93%E6%96%B9%E5%85%A5%E9%A9%BB.png"
        alt=""
      />
    </div>
    <div class="channelJoin">
      <div class="container" style="padding: 0">
        <div class="container" style="border: 0">
          <div class="main">
            <div class="titles">渠道方入驻申请</div>
            <div class="main-basic-information">
              <!--              :style="`box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);height:${this.heightFloat}`"-->
              <div
                class="main-basic-information-content"
                style="margin: 20px 100px 0 20px"
              >
                <el-form
                  ref="form"
                  :model="form"
                  label-width="1rem"
                  :rules="rules"
                  :label-position="labelPosition"
                  size="0.08rem"
                  class="channelFormBox"
                >
                  <el-form-item label="机构名称" prop="name">
                    <el-input
                      ref="name"
                      v-model="form.name"
                      maxlength="100"
                      show-word-limit
                      placeholder="请填写营业执照、组织证件照或三证合一证件照上的名称"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="机构证件代码" prop="code">
                    <el-input
                      type="text"
                      v-model.trim="form.code"
                      maxlength="100"
                      id="valueInput"
                      v-Alphabet
                      show-word-limit
                      placeholder="请填写三证合一代码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="联系人姓名" prop="username">
                    <el-input
                      v-model="form.username"
                      maxlength="100"
                      show-word-limit
                      placeholder="请填写联系人姓名"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="联系电话" prop="phone">
                    <el-input
                      placeholder="请输入联系电话"
                      maxlength="11"
                      show-word-limit
                      oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                      @blur="phoneChange"
                      v-model.trim="form.phone"
                    >
                      <template slot="prepend">+86</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="联系邮箱" prop="email">
                    <el-input
                      v-model="form.email"
                      maxlength="50"
                      show-word-limit
                      placeholder="请填写联系邮箱"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="座机号码" prop="landline">
                    <el-input
                      v-model="form.landline"
                      maxlength="100"
                      show-word-limit
                      placeholder="请填写座机号码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="机构地址">
                    <el-cascader
                      size="large"
                      :options="options"
                      v-model="form.selectedOptions"
                      @change="handleChange"
                    >
                    </el-cascader>
                  </el-form-item>
                  <el-form-item label="详细地址">
                    <el-input
                      v-model="form.address"
                      maxlength="200"
                      show-word-limit
                      placeholder="请填写详细地址"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="机构简介" prop="Introduction">
                    <el-input
                      type="textarea"
                      maxlength="200"
                      show-word-limit
                      v-model="form.Introduction"
                      placeholder="请填写机构简介"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="titles1">认证信息</div>
            <div class="main-basic-information">
              <!--              :style="`box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);`"-->
              <div
                class="main-basic-information-content"
                style="margin: 20px 100px 0 20px"
              >
                <el-form
                  label-width="1rem"
                  class="demo-ruleForm"
                  :rules="rules"
                  :label-position="labelPosition"
                >
                  <el-form-item
                    label="企业年检报告"
                    prop="annualInspectionReportEnterprises"
                    class="flexBox uploadImg"
                  >
                    <a-upload
                      name="avatar"
                      list-type="picture-card"
                      class="avatar-uploader"
                      :show-upload-list="false"
                      :customRequest="
                        successUploadAnnualInspectionReportEnterprises
                      "
                    >
                      <img
                        v-if="AnnualInspectionReportEnterprisesImg != ''"
                        :src="AnnualInspectionReportEnterprisesImg"
                        alt="avatar"
                        style="width: 100%"
                      />
                      <div v-else>
                        <a-icon :type="loading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text"></div>
                      </div>
                    </a-upload>
                  </el-form-item>
                  <el-form-item
                    label="三合一营业执照"
                    prop="threeInOneBusinessLicense"
                    class="flexBox uploadImg"
                  >
                    <a-upload
                      name="avatar"
                      list-type="picture-card"
                      class="avatar-uploader"
                      :show-upload-list="false"
                      :customRequest="successUploadThreeInOneBusinessLicense"
                    >
                      <img
                        v-if="ThreeInOneBusinessLicenseImg != ''"
                        :src="ThreeInOneBusinessLicenseImg"
                        alt="avatar"
                        style="width: 100%"
                      />
                      <div v-else>
                        <a-icon :type="loading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text"></div>
                      </div>
                    </a-upload>
                    <div class="el-upload__tip">
                      支持JPG、JPEG、PNG格式，文件不大于10M
                    </div>
                    <!--                                        <el-upload-->
                    <!--                                            drag-->
                    <!--                                            :data="userToken"-->
                    <!--                                            class="avatar-uploader"-->
                    <!--                                            action="string"-->
                    <!--                                            :on-preview="downloadUploadImg"-->
                    <!--                                            :on-remove="onRemoveUploadImgThreeInOneBusinessLicense"-->
                    <!--                                            :http-request="successUploadThreeInOneBusinessLicense"-->
                    <!--                                            :file-list="ThreeInOneBusinessLicense"-->
                    <!--                                            multiple-->
                    <!--                                            :limit="1"-->
                    <!--                                        >-->
                    <!--                                            <i class="el-icon-upload"></i>-->
                    <!--                                            <div class="el-upload__text"><em>三合一营业执照</em></div>-->
                    <!--                                            <div class="el-upload__tip" slot="tip">-->
                    <!--                                                支持JPG、JPEG、PNG格式，文件不大于10M-->
                    <!--                                            </div>-->
                    <!--                                        </el-upload>-->

                    <!--                                        <el-upload-->
                    <!--                                            class="avatar-uploader"-->
                    <!--                                            action="string"-->
                    <!--                                            :show-file-list="false"-->
                    <!--                                            :http-request="successUploadThreeInOneBusinessLicense">-->
                    <!--                                            <img v-if="imageUrl" :src="imageUrl" class="avatar">-->
                    <!--                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
                    <!--                                        </el-upload>-->
                  </el-form-item>
                  <!-- 选择上传资质 -->
                  <el-form-item
                    label="选择上传资质
                                （6选1即可）"
                    prop="businessLicense"
                    class="lastUploadImg"
                  >
                    <el-radio-group v-model="RadioSelect">
                      <div style="width: 100%; height: 10px"></div>
                      <el-radio :label="0"
                        >《信息网络传播视听节目许可证》
                      </el-radio>
                      <div style="width: 100%; height: 10px"></div>
                      <el-radio :label="1"
                        >《广播电视节目制作经营许可证》
                      </el-radio>
                      <div style="width: 100%; height: 10px"></div>
                      <el-radio :label="2"
                        >《广播电视频道许可证》（适用于电视台）
                      </el-radio>
                      <div style="width: 100%; height: 10px"></div>
                      <el-radio :label="3"
                        >《广播电视节目播出机构许可证》（适用于电视台）
                      </el-radio>
                      <div style="width: 100%; height: 10px"></div>
                      <el-radio :label="4"
                        >《统一社会信用代码》及《情况说明函件》（适用于政府主体）
                      </el-radio>
                      <div style="width: 100%; height: 10px"></div>
                      <el-radio :label="5"
                        >《事业单位法人证书》及该主体的主管部门许可文件（适用于事业单位主体）
                      </el-radio>
                      <div style="width: 100%; height: 10px"></div>
                    </el-radio-group>
                    <a-upload
                      name="avatar"
                      list-type="picture-card"
                      class="avatar-uploader"
                      :show-upload-list="false"
                      :customRequest="successUploadBusinessLicense"
                    >
                      <img
                        v-if="BusinessLicenseImg != ''"
                        :src="BusinessLicenseImg"
                        alt="avatar"
                        style="width: 100%"
                      />
                      <div v-else>
                        <a-icon :type="loading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text"></div>
                      </div>
                    </a-upload>
                    <div class="el-upload__tip">
                      上传原件或复印件，复印件务必加盖公司公章
                      支持JPG、JPEG、PNG格式，文件不大于10M
                    </div>
                    <!--                                        <el-upload-->
                    <!--                                            drag-->
                    <!--                                            :data="userToken"-->
                    <!--                                            class="upload-demo"-->
                    <!--                                            action="string"-->
                    <!--                                            :on-preview="downloadUploadImg"-->
                    <!--                                            :on-remove="onRemoveUploadImgBusinessLicense"-->
                    <!--                                            :http-request="successUploadBusinessLicense"-->
                    <!--                                            :file-list="BusinessLicense"-->
                    <!--                                            multiple-->
                    <!--                                            :limit="1"-->
                    <!--                                        >-->
                    <!--                                            <i class="el-icon-upload"></i>-->
                    <!--                                            <div class="el-upload__text"><em>资质文件</em></div>-->
                    <!--                                            <div class="el-upload__tip" slot="tip">-->
                    <!--                                                上传原件或复印件，复印件务必加盖公司公章-->
                    <!--                                                支持JPG、JPEG、PNG格式，文件不大于10M-->
                    <!--                                            </div>-->
                    <!--                                        </el-upload>-->
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="bottom" style="margin-top: 0.15rem">
              <el-switch v-model="SwitchValue"></el-switch>
              <span
                >&nbsp;&nbsp;&nbsp;&nbsp;同意《青蛙视频开放平台入驻协议》</span
              >
              <span style="display: block; margin-top: 20px">
                请您仔细阅读《青蛙视频开发平台入驻协议》，勾选后进入下一步即视为您同意遵守该协议的约定
              </span>
              <div class="submit" @click="ApplicationSubmission">
                <el-button type="primary"
                  >提&nbsp;&nbsp;&nbsp;&nbsp;交
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { provinceAndCityData,CodeToText } from 'element-china-area-data'
import { provinceAndCityData, CodeToText } from "element-china-area-data";

let OSS = require("ali-oss");
import Vue from "vue";

Vue.directive("Alphabet", {
  inserted: function (el) {
    const input = el.querySelector("#valueInput");
    input.onkeyup = function (e) {
      input.value = input.value.replace(/[^A-Za-z0-9]/g, "");
    };
    input.onblur = function (e) {
      input.value = input.value.replace(/[^A-Za-z0-9]/g, "");
    };
  },
});
export default {
  name: "channelJoin",
  data() {
    let validateName = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error("请填写营业执照、组织证件照或三证合一证件照上的名称")
        );
      }
      if (value.length < 2 || value.length > 100) {
        callback(new Error("请填写2-100个字符"));
      }
    };
    let validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请填写三证合一代码"));
      }
      if (value.length < 2 || value.length > 100) {
        callback(new Error("请填写2-100个字符"));
      }
    };
    let validateUserName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请填写联系人姓名"));
      }
      if (value.length < 2 || value.length > 100) {
        callback(new Error("请填写2-100个字符"));
      }
    };
    let validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    };
    let validateEmail = (rule, value, callback) => {
      let reg =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*.[a-zA-Z0-9]{2,6}$/;
      if (value === "") {
        callback(new Error("请填写联系邮箱"));
      }
      if (!reg.test(value)) {
        callback(new Error("邮箱格式不正确"));
      }
    };
    let validateAnnualInspectionReportEnterprises = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请上传图片"));
      }
    };
    let validateIntroduction = (rule, value, callback) => {
      if (value.length < 2 || value.length > 200) {
        callback(new Error("请填写2-200个字符"));
      }
    };
    let validateLandline = (rule, value, callback) => {
      let reg = /^[Z0-9_-]+$/;
      if (value) {
        if (value.toString().length < 2 || value.toString().length > 100) {
          callback(new Error("请填写2-100个字符"));
        } else if (!reg.test(value)) {
          callback(new Error("座机号码格式不正确"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      options: provinceAndCityData,
      userToken: {},
      labelPosition: "right",
      form: {
        name: "",
        code: "",
        username: "",
        phone: "",
        email: "",
        landline: "",
        selectedOptions: [],
        address: "",
        Introduction: "",
      },
      rulesForm: {
        name: "",
        code: "",
        username: "",
      },
      rules: {
        name: [{ required: true, validator: validateName, trigger: "blur" }],
        code: [{ required: true, validator: validateCode, trigger: "blur" }],
        Introduction: [{ validator: validateIntroduction, trigger: "blur" }],
        landline: [{ validator: validateLandline, trigger: "blur" }],
        username: [
          { required: true, validator: validateUserName, trigger: "blur" },
        ],
        phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
        annualInspectionReportEnterprises: [
          {
            required: true,
            validator: validateAnnualInspectionReportEnterprises,
          },
        ],
        threeInOneBusinessLicense: [
          {
            required: true,
            validator: validateAnnualInspectionReportEnterprises,
          },
        ],
        businessLicense: [
          {
            required: true,
            validator: validateAnnualInspectionReportEnterprises,
          },
        ],
      },
      BusinessLicense: [],
      AnnualInspectionReportEnterprises: [],
      ThreeInOneBusinessLicense: [],
      RadioSelect: 0,
      SwitchValue: false, //是否选中协议
      heightFloat: "4rem",
      heightFloat2: "5.5rem",
      AnnualInspectionReportEnterprisesImg: "", // 企业年检报告
      ThreeInOneBusinessLicenseImg: "", // 三合一营业执照
      BusinessLicenseImg: "", // 资质文件
    };
  },
  watch: {
    $route: "reload",
    "form.phone": function (curVal) {
      if (!curVal) {
        this.from.phone = "";
        return false;
      }
      // 实时把非数字的输入过滤掉
      this.from.phone = curVal.match(/\d/gi)
        ? curVal.match(/\d/gi).join("")
        : "";
    },
  },
  mounted() {
    // console.log()
    let clientWidth = document.documentElement.clientWidth;
    if (clientWidth > 1920) {
      this.heightFloat = "4rem";
      this.heightFloat2 = "5.5rem";
    }
    if (Number(clientWidth) < 1700) {
      this.heightFloat2 = "6.5rem";
    }
    if (Number(clientWidth) < 1500) {
      this.heightFloat2 = "7rem";
    }
    if (Number(clientWidth) < 1400) {
      this.heightFloat2 = "7.5rem";
    }
    if (Number(clientWidth) < 1300) {
      this.heightFloat2 = "8rem";
    }
    if (Number(clientWidth) < 1250) {
      this.heightFloat2 = "9rem";
    }
    if (Number(clientWidth) < 1550) {
      this.heightFloat = "4.5rem";
    }
    if (Number(clientWidth) < 1450) {
      this.heightFloat = "5rem";
    }
    if (Number(clientWidth) < 1300) {
      this.heightFloat = "5.5rem";
    }
    if (Number(clientWidth) < 1250) {
      this.heightFloat = "6rem";
    }
  },
  methods: {
    handleChange(value) {
      console.log(CodeToText[value[0]]);
      // CodeToText(value[0])
    },
    toLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    /**
     * 下载已上传的图片
     * @method downloadUploadImg
     */
    downloadUploadImg(file) {
      console.log(file);
      // 上传成功且成功返回上传数据
      if (file.status == '"success"' && file.url != "") {
        window.open(file.url);
      } else if (file.response.code == 200 && file.response.data != "") {
        console.log("执行这里，跳转的url=>", file.response.data);
        window.open(file.response.data);
      } else {
        this.$Message.error("图片地址出错！");
      }
    },
    /**
     * 删除已上传的经营许可证
     * @method onRemoveUploadImgBusinessLicense
     */
    onRemoveUploadImgBusinessLicense(file, fileList) {
      console.log(file, fileList);
      this.BusinessLicense = fileList;
      console.log(this.BusinessLicense);
    },
    // 成功上传经营许可证
    successUploadBusinessLicense(param) {
      // 请求下发AkSk
      this.$axios.get(`/oss/getQuaSToken`).then((res) => {
        console.log("res=>", res);
        let self = this;
        let data = res.data.data;
        if (res.data.code == 0) {
          let client = new OSS({
            region: data.region,
            accessKeyId: data.accessKeyId,
            accessKeySecret: data.accessKeySecret,
            bucket: data.bucketName,
            stsToken: data.securityToken,
          });
          const dataParam = param.file;
          console.log(dataParam);
          let traceId =
            new Date().getTime() + Math.round(Math.random() * 10000);

          async function putObject(val) {
            console.log();
            try {
              let result = await client.put(
                traceId + dataParam.name,
                dataParam
              );
              console.log("result=>", result);
              self.BusinessLicense.push({
                uid: param.uid,
                name: result.url,
                url: result.url,
              });
              self.BusinessLicenseImg = result.url;
              console.log(self.BusinessLicense);
            } catch (e) {
              console.log("error=>", e);
            }
            // this.Module3.AuthorizationFile = imgUrl
          }

          putObject();
          // console.log(this.Module3.AuthorizationFile)
        } else {
          this.$message.error("用户状态有误，请检查并重新登陆");
        }
      });
    },
    /**
     * 删除已上传的企业年检报告
     * @method onRemoveUploadImgBusinessLicense
     */
    onRemoveUploadImgAnnualInspectionReportEnterprises(file, fileList) {
      console.log(file, fileList);
      this.AnnualInspectionReportEnterprises = fileList;
      console.log(this.AnnualInspectionReportEnterprises);
    },
    // 成功上传企业年检报告
    successUploadAnnualInspectionReportEnterprises(param) {
      // 请求下发AkSk
      this.$axios.get(`/oss/getQuaSToken`).then((res) => {
        console.log(res);
        let self = this;
        let data = res.data.data;
        if (res.data.code == 0) {
          let client = new OSS({
            region: data.region,
            accessKeyId: data.accessKeyId,
            accessKeySecret: data.accessKeySecret,
            bucket: data.bucketName,
            stsToken: data.securityToken,
          });
          const dataParam = param.file;
          console.log(dataParam);
          let traceId =
            new Date().getTime() + Math.round(Math.random() * 10000);

          async function putObject(val) {
            console.log();
            try {
              let result = await client.put(
                traceId + dataParam.name,
                dataParam
              );
              console.log("result=>", result);
              self.AnnualInspectionReportEnterprises.push({
                uid: param.uid,
                name: result.url,
                url: result.url,
              });
              console.log(self.AnnualInspectionReportEnterprises);
              self.AnnualInspectionReportEnterprisesImg = result.url;
            } catch (e) {
              console.log("error=>", e);
            }
            // this.Module3.AuthorizationFile = imgUrl
          }

          putObject();
          // console.log(this.Module3.AuthorizationFile)
        } else {
          this.$message.error("用户状态有误，请检查并重新登陆");
        }
      });
    },
    /**
     * 删除已上传的企业年检报告
     * @method onRemoveUploadImgThreeInOneBusinessLicense
     */
    onRemoveUploadImgThreeInOneBusinessLicense(file, fileList) {
      console.log(file, fileList);
      this.ThreeInOneBusinessLicense = fileList;
      console.log(this.ThreeInOneBusinessLicense);
    },
    // 成功上传三合一营业执照
    successUploadThreeInOneBusinessLicense(param) {
      // 请求下发AkSk
      this.$axios.get(`/oss/getQuaSToken`).then((res) => {
        console.log(res);
        let self = this;
        let data = res.data.data;
        if (res.data.code == 0) {
          let client = new OSS({
            region: data.region,
            accessKeyId: data.accessKeyId,
            accessKeySecret: data.accessKeySecret,
            bucket: data.bucketName,
            stsToken: data.securityToken,
          });
          const dataParam = param.file;
          console.log(dataParam);
          let traceId =
            new Date().getTime() + Math.round(Math.random() * 10000);

          async function putObject(val) {
            console.log();
            try {
              let result = await client.put(
                traceId + dataParam.name,
                dataParam
              );
              console.log("result=>", result);
              self.ThreeInOneBusinessLicense.push({
                uid: param.uid,
                name: result.url,
                url: result.url,
              });
              self.ThreeInOneBusinessLicenseImg = result.url;
            } catch (e) {
              console.log("error=>", e);
            }
            // this.Module3.AuthorizationFile = imgUrl
          }

          putObject();
          // console.log(this.Module3.AuthorizationFile)
        } else {
          this.$message.error("用户状态有误，请检查并重新登陆");
        }
      });
    },
    //限制手机号码类型
    phoneChange(e) {
      this.form.phone = e.target.value;
    },
    ApplicationSubmission() {
      if (this.SwitchValue == false) {
        this.$message.error("请先同意《青蛙视频开放平台入驻协议》");
        return false;
      }
      // 判断参数是否已上传
      if (this.form.name == "") {
        this.$message.error("请先填写机构名称");
        return false;
      }
      if (this.form.code == "") {
        this.$message.error("请先填写机构证件代码");
        return false;
      }
      if (this.form.username == "") {
        this.$message.error("请先填写联系人姓名\n");
        return false;
      }
      if (this.form.phone == "") {
        this.$message.error("请先填写联系电话\n");
        return false;
      }
      if (this.form.email == "") {
        this.$message.error("请先填写联系邮箱\n");
        return false;
      }
      if (this.AnnualInspectionReportEnterprisesImg == "") {
        this.$message.error("请先上传企业年检报告\n");
        return false;
      }
      if (this.ThreeInOneBusinessLicenseImg == "") {
        this.$message.error("请先上传三合一营业执照\n");
        return false;
      }
      if (this.BusinessLicenseImg == "") {
        this.$message.error("请先上传资质文件\n");
        return false;
      }
      let data = {
        type: 1,
        userName: this.form.name,
        code: this.form.code,
        phoneName: this.form.username,
        phone: this.form.phone,
        email: this.form.email,
        landline: this.form.landline,
        province: CodeToText[this.form.selectedOptions[0]],
        city: CodeToText[this.form.selectedOptions[1]],
        address: this.form.address,
        describe: this.form.Introduction,
        report: this.AnnualInspectionReportEnterprisesImg,
        businessLicense: this.ThreeInOneBusinessLicenseImg,
        qualificationsType: this.RadioSelect,
        qualifications: this.BusinessLicenseImg,
      };
      console.log(data);
      this.$axios
        .post(`/account/user/insert`, data, {})
        .then((res) => {
          console.log("res=>", res);
          if (res.data.code == 0) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.$router.push({
              path: "/",
            });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch();
    },
  },
};
</script>

<style>
.channelFormBox {
  padding-top: 0.14rem;
}

.channelFormBox .el-form-item__label {
  font-weight: 600 !important;
  font-size: 0.1rem !important;
}

.channelFormBox .el-form-item {
  height: 0.5rem;
  margin-bottom: 0;
}

.main-basic-information .el-form-item__content .ant-upload {
  /*margin-left: 1.2rem !important;*/
  width: 1rem !important;
  height: 1rem !important;
}

.main-basic-information .uploadImg .el-form-item__content {
  margin-left: 0 !important;
}

.main-basic-information .lastUploadImg .el-form-item__content {
  margin-left: 1.4rem !important;
}

.demo-ruleForm {
  margin-top: 0.3rem;
}

.demo-ruleForm .el-form-item__label {
  font-weight: 600;
  width: 1.4rem !important;
}

.demo-ruleForm .el-upload__tip {
  /*padding-left: .5rem;*/
}

.upload-demo:nth-of-type(2) .el-upload {
  margin-left: 0.4rem;
}

/*.demo-ruleForm .el-upload:nth-of-type(3){*/
/*  margin-left: .4rem;*/
/*}*/
</style>

<style scoped>
#box {
  width: 100%;
  height: 100%;
}

.copyright-top {
  height: 3rem;
  width: 100%;
}

.copyright-top > img {
  width: 100%;
  height: 100%;
}

html,
body,
.channelJoin {
  width: 100%;
  height: 100%;
  overflow: auto !important;
  font-size: 0.08rem;
}

.main {
  width: 7rem;
  margin-left: 1.7rem;
}

.index-header {
  width: 100%;
  height: 64px;
}

.index-header-logo {
  width: 0.69rem;
  height: 0.22rem;
  margin-left: 1.935rem;
  margin-top: 10px;
  float: left;
}

.index-header-logo img {
  width: 100%;
  height: 100%;
}

.index-header-login {
  width: 0.4rem;
  height: 0.15rem;
  float: right;
  margin-right: 1.95rem;
  margin-top: 0.1rem;
}

.index-header-login-submit {
  width: 80px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  background: #00c2af;
  border-radius: 15px;
  border: 1px solid #00c2af;
}

.main-basic-information {
  width: 100%;
  /*//border: 1px solid #ccc;*/
  margin-top: 0.12rem;
}

.submit {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-top: 10px;
}

.titles,
.titles1 {
  font-size: 0.12rem;
  font-weight: 600;
  color: #000000;
  height: 0.23rem;
  position: relative;
  margin-top: 0.3rem;
}

.titles:after {
  content: "";
  width: 1rem;
  height: 0.03rem;
  background: #00c2af;
  position: absolute;
  bottom: 0;
  left: 0;
}

.titles1:after {
  content: "";
  width: 0.6rem;
  height: 0.03rem;
  background: #00c2af;
  position: absolute;
  bottom: 0;
  left: 0;
}

.flexBox {
  display: flex;
}
</style>
