










































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
import OSS from "ali-oss";

export default {
  data() {
    let validateIntroduction = (rule, value, callback) => {
      if (value.length < 2 || value.length >200) {
        callback(new Error("请填写2-200个字符"));
      }
    };
    let institutionNames = (rule, value, callback) => {
      if (value === "") {
        callback(
          new Error("请填写营业执照、组织证件照或三证合一证件照上的名称")
        );
      }
      if (value.length < 2 || value.length > 100) {
        callback(new Error("请填写2-100个字符"));
      }
    };
    let validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请填写机构证件代码"));
      }
      if (value.length < 2 || value.length > 100) {
        callback(new Error("请填写2-100个字符"));
      }
    };
    let validateUserName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请填写联系人姓名"));
      }
      if (value.length < 2 || value.length > 100) {
        callback(new Error("请填写2-100个字符"));
      }
    };
    let validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!/^1[3456789]\d{9}$/.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    };
    let validateEmail = (rule, value, callback) => {
      let reg =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*.[a-zA-Z0-9]{2,6}$/;
      if (value === "") {
        callback(new Error("请填写联系邮箱"));
      }
      if (!reg.test(value)) {
        callback(new Error("邮箱格式不正确"));
      }
    };
    let validateAnnualInspectionReportEnterprises = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请上传图片"));
      }
    };
    let validateLandline = (rule, value, callback) => {
     let reg = /^[Z0-9_-]+$/;
      if (value) {
        if (value.toString().length < 2 || value.toString().length > 100) {
          callback(new Error("请填写2-100个字符"));
        } else if (!reg.test(value)) {
          callback(new Error("座机号码格式不正确"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      formInfo: {
        institutionName: "", // 机构名称
        institutionCard: "", // 机构证件代码
        contactName: "", // 联系人姓名
        contactPhone: "", // 联系电话
        contactEmail: "", // 联系邮箱
      },
      institutionName: "", // 机构名称
      institutionCard: "", // 机构代码
      contactName: "", // 联系姓名
      contactPhone: "", // 联系电话
      contactEmail: "", // 联系邮箱
      machineNumber: "", // 座机号码
      province: "", // 选择省份
      city: "", // 选择市级
      options: provinceAndCityData, // 省市区列表
      selectedOptions: "", // 已选省市区
      DetailAddress: "", // 详细地址
      profileInstitutional: "", // 机构简介
      televisionProgramImgUrl: "", // 经营许可证
      annualInspectionReportImgUrl: "", // 年检报告
      businessLicenseImgUrl: "", // 营业执照
      loading: "",
      checked: false,
      rulesForm: {},
      rules: {
        profileInstitutional: [
          { validator: validateIntroduction, trigger: "blur" },
        ],
        institutionName: [
          { required: true, validator: institutionNames, trigger: "blur" },
        ],
        institutionCard: [
          { required: true, validator: validateCode, trigger: "blur" },
        ],
        contactName: [
          { required: true, validator: validateUserName, trigger: "blur" },
        ],
        contactPhone: [
          { required: true, validator: validatePhone, trigger: "blur" },
        ],
        contactEmail: [
          { required: true, validator: validateEmail, trigger: "blur" },
        ],
        annualInspectionReportEnterprises: [
          {
            required: true,
            validator: validateAnnualInspectionReportEnterprises,
          },
        ],
        threeInOneBusinessLicense: [
          {
            required: true,
            validator: validateAnnualInspectionReportEnterprises,
          },
        ],
        businessLicense: [
          {
            required: true,
            validator: validateAnnualInspectionReportEnterprises,
          },
        ],
        machineNumber: [{ validator: validateLandline, trigger: "blur" }],
      },
    };
  },
  methods: {
    /**
     * 选择地区切换
     * @method handleChange
     */
    handleChange(value) {
      this.province = CodeToText[value[0]];
      this.city = CodeToText[value[1]];
    },

    /**
     * 上传成功事件
     * @method successUploadStill
     * params {object} e 上传成功的事件对象
     */
    televisionProgramImgUrlUpload(param: any): void {
      const _self: any = this;
      Vue.prototype.$axios.get(`/oss/getQuaSToken `).then((res) => {
        let data = res.data.data;
        if (res.data.code == 0) {
          let client = new OSS({
            region: data.region,
            accessKeyId: data.accessKeyId,
            accessKeySecret: data.accessKeySecret,
            bucket: data.bucketName,
            stsToken: data.securityToken,
          });
          const dataParam = param.file;
          let traceId =
            new Date().getTime() + Math.round(Math.random() * 10000);
          // eslint-disable-next-line no-inner-declarations
          async function putObject() {
            try {
              let result = await client.put(
                traceId + dataParam.name,
                dataParam
              );
              _self.televisionProgramImgUrl = result.url;
            } catch (e) {
              console.log("error=>", e);
            }
          }

          putObject();
        } else {
          console.log("111");
        }
      });
    },

    //限制手机号码类型
    phoneChange(e): void {
      const _self: any = this;
      _self.formInfo.contactPhone = e.target.value;
    },

    annualInspectionReportImgUrlUpload(param: any): void {
      const _self: any = this;
      Vue.prototype.$axios.get(`/oss/getQuaSToken `).then((res) => {
        let data = res.data.data;
        if (res.data.code == 0) {
          let client = new OSS({
            region: data.region,
            accessKeyId: data.accessKeyId,
            accessKeySecret: data.accessKeySecret,
            bucket: data.bucketName,
            stsToken: data.securityToken,
          });
          const dataParam = param.file;
          let traceId =
            new Date().getTime() + Math.round(Math.random() * 10000);
          // eslint-disable-next-line no-inner-declarations
          async function putObject() {
            try {
              let result = await client.put(
                traceId + dataParam.name,
                dataParam
              );
              _self.annualInspectionReportImgUrl = result.url;
            } catch (e) {
              console.log("error=>", e);
            }
          }

          putObject();
        } else {
          console.log("111");
        }
      });
    },

    businessLicenseImgUrlUpload(param: any): void {
      const _self: any = this;
      Vue.prototype.$axios.get(`/oss/getQuaSToken `).then((res) => {
        let data = res.data.data;
        if (res.data.code == 0) {
          let client = new OSS({
            region: data.region,
            accessKeyId: data.accessKeyId,
            accessKeySecret: data.accessKeySecret,
            bucket: data.bucketName,
            stsToken: data.securityToken,
          });
          const dataParam = param.file;
          let traceId =
            new Date().getTime() + Math.round(Math.random() * 10000);
          // eslint-disable-next-line no-inner-declarations
          async function putObject() {
            try {
              let result = await client.put(
                traceId + dataParam.name,
                dataParam
              );
              _self.businessLicenseImgUrl = result.url;
            } catch (e) {
              console.log("error=>", e);
            }
          }

          putObject();
        } else {
          console.log("111");
        }
      });
    },

    switchChange(checked: any): void {
      console.log(checked);
      this.checked = checked;
      console.log(this.checked);
    },

    /**
     * 点击提交
     */
    pushSubmit(): void {
      console.log(this.televisionProgramImgUrl);
      if (this.formInfo.institutionName == "") {
        return this.$message.error("请先填写机构名称");
      } else if (this.formInfo.institutionCard == "") {
        return this.$message.error("请先填写机构证件代码");
      } else if (this.formInfo.contactName == "") {
        return this.$message.error("请先填写联系人姓名");
      } else if (this.formInfo.contactPhone == "") {
        return this.$message.error("请先填写联系电话");
      } else if (this.formInfo.contactEmail == "") {
        return this.$message.error("请先填写联系邮箱");
      } else if (this.televisionProgramImgUrl == "") {
        return this.$message.error("请上传广播电视节目制作经营许可证");
      } else if (this.annualInspectionReportImgUrl == "") {
        return this.$message.error("请上传企业年检报告");
      } else if (this.businessLicenseImgUrl == "") {
        return this.$message.error("请上传三合一营业执照");
      }
      if (!this.checked) {
        return this.$message.error("请先同意《青蛙视频开放平台入驻协议》");
      }

      let data = {
        type: 0,
        userName: this.formInfo.institutionName,
        code: this.formInfo.institutionCard,
        phoneName: this.formInfo.contactName,
        phone: this.formInfo.contactPhone,
        email: this.formInfo.contactEmail,
        landline: this.rulesForm.machineNumber,
        province: this.province,
        city: this.city,
        address: this.DetailAddress,
        describe: this.rulesForm.profileInstitutional,
        televisionIssue: this.televisionProgramImgUrl,
        report: this.annualInspectionReportImgUrl,
        businessLicense: this.businessLicenseImgUrl,
      };
      console.log(data);
      Vue.prototype.$axios
        .post(`/account/user/insert`, data, {})
        .then((res) => {
          console.log("res=>", res);
          if (res.data.code == 0) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.$router.push({
              path: "/",
            });
          }else{
            this.$message.error(res.data.msg);
          }
        })
        .catch();
    },
  },
};
