<template>
    <div class="box">
        <div class="headerBack">
            <img src="https://q-res.oss-cn-beijing.aliyuncs.com/qingwa-index/%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.png"
                 alt=""/>
        </div>
        <div class="content">
            <div class="title">
                服务协议
            </div>
            <div class="contentText">
                <p>欢迎您使用青蛙影视区块链发行平台的服务！</p>
                <p><b>1、导言</b></p>

                <p>1.1
                    欢迎使用由天津青蛙视频有限公司（以下简称“青蛙视频”或者“我们”）提供的青蛙影视区块链发行平台（以下简称“本平台”）。青蛙影视区块链发行平台服务条款（以下简称“本条款”）是您与我们之间的协议，载列了您访问和使用本平台及由天津青蛙视频有限公司或其关联方负责运用、提
                    供的相关网站、服务、应用程序、产品及内容（统称为“本服务”）须遵守的条款及条件。就本条款而言，“您”是指本服务的用户。</p>
                <p>1.2
                    本条款构成您与我们之间具法律约束力的协议。我们在此特别提醒您认真阅读、充分理解本条款各条款，特别是其中所涉及的免除或减轻我们责任的条款、限制您权利的条款、关于争议解决与法律适用的条款等。其中，限制您权利、免除或减轻我们责任的条款会以黑体加粗的形式提示您重点注意。</p>
                <p>1.3 您访问或使用本服务即表示您确认：您具有与青蛙视频形成达成具有约束力的合同所需的民事权利能力和民事行为能力，接受并同意遵守本条款。</p>
                <p>1.4
                    如果您代表任何企业等机构组织访问或使用本服务，您承诺已获得该机构组织允许您代表其接受、履行本条款所需的完整授权。在此情况下，该组织在法律和财务方面对以下各项行为承担责任：您对本服务的访问和使用，以及与组织机构相关联的其他人士（包括任何员工、代理人或合同方）对您账号的使用。</p>
                <p>1.5
                    我们会不时修订本条款，例如：我们会在更新本服务功能或监管规定发生变动时修订本条款。在进行本条款的修订时，我们将在本平台或我们的网站公开征求意见，并在修订内容正式实施前七日对修订内容予以公示。我们还会及时更新本条款顶部的“更新日期”和“生效日期”。我们会尽合理努力向所有用户发出合理的通知（例如通过本平台的通知），以向您告知本条款的重大变更，您应及时关注。您在本条款更新之后继续访问或使用本服务，即视为您接受变更后的条款。如果您不同意更新后的条款，您应停止访问或使用本服务。</p>
                <p>1.6 如我们需要您遵守除本条款之外的其他条款的，该等条款的公示、发布及效力亦遵守上述约定。</p>
                <p>1.7
                    除非您已阅读并接受本条款及相关协议的所有条款，否则您无权使用本服务。您对本服务的注册、登录、查看、发布信息、帐号管理等行为即视为您已阅读并同意本条款的约束。您有违反本条款的任何行为时，青蛙视频有权依照违反情况，随时单方限制、中止或终止向您提供的服务，并有权追究您的相关责任。</p>

                <p><b>2、帐号注册及帐号权限 </b></p>

                <p>2.1
                    您在使用本服务前需要在本平台提交相关企业资质信息，本平台根据您提供的资质进行审核，审核通过后，为您开通账号及相关权限，青蛙视频有权根据用户需求和产品需要对帐号注册和使用权限进行变更，关于您使用帐号的具体规则，请遵守青蛙视频或者本平台为此发布的专项规则。</p>

                <p>2.2
                    本平台账号注册采用真实公司资质认证，您应当如实填写和提交帐号注册与认证资料，完成信息登记，并对资料的真实性、合法性、准确性和有效性承担责任。青蛙视频及本平台依据您填写和提交的资料，在法律允许的范围内向其他用户展示您的注册信息。如您上传、发布的内容需要取得相关法律法规规定的许可、进行备案或取得相关合法资质的（包括但不限于新闻信息采编传播资质、互联网视听内容制作及传播资质等），则您应自行取得相应的许可、备案或资质证明。否则，青蛙视频有权拒绝或终止提供本服务，并依照本条款对违规帐号进行处罚。因此给青蛙视频或第三方造成损害的，您应当依法予以赔偿。</p>

                <p>2.3 您理解并同意：</p>

                <p>（1）您用以参与本服务的本平台帐号由您独立维护、运营并独立承担全部责任。青蛙视频不会、也不可能参与该青蛙影视区块链发行平台帐号的运营等独立活动； </p>

                <p>（2）青蛙视频自主开发并独立享有本服务技术接口的知识产权等合法权益，您仅在本服务范围内进行使用； </p>

                <p>
                    （3）因您的原因导致的任何纠纷、责任等，以及您或您的青蛙影视区块链发行平台帐号违反相关法律法规或本条款约定引发的任何后果，均由您独立承担责任、赔偿损失，与青蛙视频无关。如侵害到青蛙视频或他人权益的，您须自行承担全部责任和赔偿一切损失。</p>

                <p>2.4 使用本服务，您可获得以下权限和义务：</p>

                <p>2.4.1 您可以使用青蛙视频提供的版权内容作为创作素材来源，具体使用版权内容及范围，根据购买产品区别不同，详见青蛙视频产品说明。</p>

                <p>2.4.2
                    为了最大限度的保障您的权益，您申请入驻并开通青蛙影视区块链发行平台账号成功后，即代表您在授权青蛙视频合同期内将对您以该青蛙影视区块链发布平台账号发布在本平台中的内容在指定渠道或入驻本平台的渠道进行分发。</p>

                <p>2.4.3
                    为了维护您和我们共同的权益，接受本条款服务，意味着您承诺通过青蛙影视区块链发行平台账号发布在本平台中的内容，即接受通过本平台入驻渠道进行分发，如签署独家授权本平台，未经我们同意，您擅自将视频内容分发在其他渠道，我们有权要求您立即删除，并承担违约责任。</p>

                <p><b>3、用户信息保护 </b></p>

                <p>3.1
                    保护用户信息是青蛙视频的一项基本原则，青蛙视频将会采取合理的措施保护用户信息。除法律法规规定的情形外，未经用户许可青蛙视频不会向第三方公开、透露用户信息。青蛙视频对相关信息采用专业加密存储与传输方式，保障用户信息的安全。</p>

                <p>3.2
                    您在申请入驻本服务过程中，需要填写一些必要的信息，请保持这些信息的真实、准确、合法、有效并注意及时更新，以便青蛙视频向您提供及时有效的帮助，或更好地为您提供服务。根据相关法律法规和政策，请您填写真实信息。若您填写的信息不完整或不准确，则无法使用本服务或在使用过程中受到限制。 </p>

                <p>3.3 一般情况下，您可根据平台规定浏览、修改自己提交的信息，但出于安全性和身份识别的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。</p>

                <p>3.4 青蛙视频将运用各种安全技术和程序建立完善的管理制度来保护您的信息，以免遭受未经授权的访问、使用或披露。 </p>

                <p>3.5 您应对通过本服务了解、接收或可接触到的包括但不限于其他用户在内的任何信息予以充分尊重，您不应以搜集、复制、存储、传播或以其他任何方式使用其他用户的信息，否则，由此产生的后果由您自行承担。</p>

                <p><b>4、用户行为规范</b></p>

                <p>4.1 信息内容规范 </p>

                <p>4.1.1
                    本条款所述信息内容是指用户使用本服务过程中所制作、复制、发布、传播的任何内容，包括但不限于青蛙影视区块链发布平台帐号名称、用户说明等注册信息及认证资料，或文字、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用青蛙影视区块链发布平台帐号或平台服务所产生的内容，也称“青蛙影视区块链发布平台信息内容”。 </p>

                <p>4.1.2
                    您理解并同意，青蛙影视区块链发布平台一直致力于为用户提供文明健康、规范有序的网络环境，您不得利用本平台帐号或青蛙影视区块链发布平台服务制作、复制、发布、传播如下干扰青蛙影视区块链发布平台正常运营，以及侵犯其他用户或第三方合法权益的内容： </p>

                <p>4.1.2.1 发布、传送、传播、储存违反国家法律法规禁止的内容： </p>

                <p>（1）违反宪法确定的基本原则的； </p>

                <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； </p>

                <p>（3）损害国家荣誉和利益的；</p>

                <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的； </p>

                <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的； </p>

                <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的； </p>

                <p>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>

                <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>

                <p>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</p>

                <p>（10）以非法民间组织名义活动的；</p>

                <p>（11）不符合《即时通信工具公众信息服务发展管理暂行规定》及遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的；</p>

                <p>（12）含有法律、行政法规禁止的其他内容的。</p>

                <p>4.1.2.2 发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</p>

                <p>4.1.2.3 涉及他人隐私、个人信息或资料的内容；</p>

                <p>4.1.2.4 发表、传送、传播骚扰信息、广告信息及垃圾信息或含有任何性或性暗示的内容；</p>

                <p>4.1.2.5 发布、传送、传播谣言、虚假信息或其他含有不实信息的内容；</p>

                <p>4.1.2.6 其他违反法律法规、政策及公序良俗、社会公德或干扰青蛙影视区块链发布平台正常运营和侵犯其他用户或第三方合法权益内容的信息。</p>

                <p>4.2 平台使用规范</p>

                <p>4.2.1 本条所述平台使用是指用户使用本服务所进行的任何行为，包括但不限于申请入驻登录、帐号运营以及其他使用青蛙影视区块链发布平台帐号或青蛙影视区块链发布平台服务所进行的行为。</p>

                <p>4.2.2 您不得利用青蛙影视区块链发布平台账号或者青蛙影视区块链发布平台服务进行如下行为：</p>

                <p>4.2.2.1 提交、发布虚假信息；</p>

                <p>4.2.2.2 虚构事实、隐瞒真相以误导、欺骗他人的；</p>

                <p>4.2.2.3 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</p>

                <p>4.2.2.4 填写和提交帐号注册与认证资料违反本条款规定，或运营行为与注册信息所公示身份无关的；</p>

                <p>4.2.2.5 未能按照青蛙影视区块链发布平台业务流程注册和使用本服务，违反本服务功能限制或运营策略，或采取任何措施规避前述流程、限制或策略，干扰青蛙影视区块链发布平台正常运营的；</p>

                <p>4.2.2.6 未经青蛙视频书面许可使用插件、外挂或通过其他第三方工具、运营平台或任何服务接入本服务和相关系统的；</p>

                <p>4.2.2.7 利用青蛙影视区块链发布平台账号或者青蛙影视区块链发布平台服务从事包括但不限于欺诈、传销、违法物品营销等任何违法犯罪活动的； </p>

                <p>4.2.2.8 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的； </p>

                <p>4.2.2.9 仿冒、混淆他人帐号昵称、功能介绍或发布内容等，或冒充、利用他人名义的；</p>

                <p>4.2.2.10
                    未经青蛙视频书面许可，自行或授权、允许、协助任何第三人对信息内容进行非法获取，用于包括但不限于宣传、增加阅读量、浏览量等商业用途的。“非法获取”是指采用包括但不限于“蜘蛛”(spider)程序、爬虫程序、拟人程序等非真实用户或避开、破坏技术措施等非正常浏览的手段、方式，读取、
                    复制、转存、获得数据和信息内容的行为；</p>

                <p>4.2.2.11
                    未经青蛙视频书面许可，与第三方达成任何形式的协议、承诺或确认，在青蛙影视区块链发布平台发布任何对本服务所提供的任何功能进行排他、排斥、排除、妨碍、阻碍、强制选择等非善意竞争的内容；</p>

                <p>4.2.2.12 任何导致或可能导致青蛙视频与第三方产生纠纷、争议或诉讼的行为。 </p>

                <p>4.2.3 您在使用本服务的过程中，不得从事包括但不限于以下行为，也不得为以下行为提供便利（包括但不限于为用户的行为提供便利等)：</p>

                <p>4.2.3.1 删除、隐匿、改变本服务显示或其中包含的任何专利、著作权、商标或其他权利声明；</p>

                <p>4.2.3.2 以任何方式干扰或企图干扰青蛙视频任何产品、任何部分或功能的正常运行，或者制作、发布、传播上述工具、方法等；</p>

                <p>4.2.3.3 在未经过用户同意的情况下，向任何其他用户及他方显示或以其他任何方式提供该用户的任何信息； </p>

                <p>4.2.3.4 为任何用户自动登录到青蛙影视区块链发布平台提供代理身份验证凭据； </p>

                <p>4.2.3.5 提供跟踪功能，包括但不限于识别其他用户在个人主页上查看、点击等操作行为；</p>

                <p>4.2.3.6 自动将浏览器窗口定向到其他网页； </p>

                <p>4.2.3.7 发布的信息内容中含有计算机病毒、木马或者其他恶意程序、链接等任何可能危害青蛙视频、本平台或用户权益和终端信息安全等的内容； </p>

                <p>4.2.3.8 其他青蛙视频认为不应该、不适当的行为、内容。 </p>

                <p>4.3 对自己行为负责 </p>

                <p class="textStyle">
                    您理解并同意，青蛙影视区块链发布平台仅为用户提供信息分享、传播及获取的平台，您必须为自己注册帐号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。青蛙视频无法且不会对因前述风险而导致的任何损失或损害承担责任。</p>

                <p>4.4 您保证：不对平台、技术接口、本服务数据进行反向工程（reverse
                    engineer）、反向编译（decompile）或反汇编（disassemble）；不对平台、技术接口、本服务数据进行内容、功能、逻辑及界面等任何方面的修改；不得将平台、技术接口、本服务数据或业务用户本条款以外目的。 </p>

                <p><b>5、帐号管理 </b></p>

                <p>5.1
                    青蛙影视区块链发布平台帐号的所有权归青蛙视频所有，您完成申请入驻并审核通过开户手续后，获得青蛙影视区块链发布平台帐号的使用权，该使用权仅属于初始申请注册主体。帐号使用权禁止赠与、借用、租用、转让或售卖。</p>

                <p>5.2
                    青蛙影视区块链发布平台账号密码由您自行设定。青蛙视频特别提醒您应妥善保管您的帐号和密码。青蛙视频与您共同负有维护帐号安全的责任。青蛙视频会采取并不断更新技术措施，努力保护您的帐号在服务器端的安全。您需要采取特定措施保护您的帐号安全，包括但不限于妥善保管青蛙影视区块链发布平台账号与密码、安装防病毒木马软件、定期更改密码等措施。当您使用完毕后，应安全退出。您同意在任何情况下不向他人透露帐号或密码信息。因您保管不善可能导致帐号被他人使用（包括但不限于遭受盗号、密码失窃）或信息数据泄漏，责任由您自行承担。</p>

                <p>您理解并同意，在您未进行投诉或提出帐号申诉等方式明确告知青蛙视频帐号被他人使用或信息数据泄漏等情况并提供相关证明材料前，青蛙视频有理由相信该帐号行为是您使用帐号的行为。</p>

                <p>5.3
                    在您怀疑他人在使用您的帐号或密码时，您同意立即通知青蛙视频。如果您当前使用的青蛙影视区块链发布平台账号并不是您初始申请注册的或者通过青蛙视频提供的其他途径获得的，但您却知悉该帐号当前的密码，您不得用该帐号登录或进行任何操作，并请您在第一时间通知青蛙视频或者该帐号的初始申请入驻注册主体。如果青蛙视频发现您并非该帐号初始申请入驻注册主体，青蛙视频有权在未经通知的情况下终止您使用该帐号。 </p>

                <p>5.4
                    您理解并同意，为保护您及其他用户的数据安全，防止用户信息泄露、毁损、篡改或者丢失，青蛙视频有权对您接入的信息系统实行接入审查，包括但不限于技术水平审查、安全水平审查、主体资质审查等，并根据审查结果向您提出防入侵、防病毒等措施建议。若您的信息系统仍无法符合保护用户数据安全的要求，青蛙视频有权拒绝或终止提供本服务。 </p>

                <p><b>5.5
                    如您违反相关法律法规、本条款以及专项规则的规定，青蛙视频有权进行独立判断并随时限制、冻结或终止您对青蛙影视区块链发布平台账号的使用，且根据实际情况决定是否恢复使用。由此给您带来的损失（包括但不限于通信中断，用户资料及相关数据清空、收益无法提现等），由您自行承担。 </b>
                </p>

                <p>5.6 如果您的青蛙影视区块链发布平台账号被盗、密码遗忘或因其他原因导致无法正常登录，您可以按照青蛙视频的官方客服进行申诉或自行密码找回。</p>

                <p>5.7 您有义务对您所发布的短视频等进行实时有效的管理，如因您管理不力而出现违反相关法令的信息或内容，青蛙视频有权限制或取消您帐号的前述相关功能。</p>

                <p><b>6、数据的储存和使用规则</b></p>

                <p>6.1 青蛙视频将按照行业标准和最佳实践采取合理措施储存和使用您的数据。对于在本服务中经合理最大努力后仍不可掌控的数据风险，青蛙视频不对相关数据删除或储存失败承担相应的责任。</p>

                <p>6.2
                    青蛙视频有权根据最小必要原则，结合实际情况自行决定单个青蛙影视区块链发行平台号用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。您可根据自己的需要自行备份本服务中的相关数据。 </p>

                <p>6.3 如果您停止使用本服务或服务被终止或被取消，青蛙视频可以从服务器上永久地删除您的数据。在服务停止、终止或取消后，青蛙视频没有义务向您返还任何数据。 </p>

                <p>6.4
                    本服务涉及的数据中归属于青蛙视频的数据，除法律法规另有规定外，青蛙视频享有全部权利，且是青蛙视频的商业秘密。未经青蛙视频事先书面同意，您不得为本条款约定之外的目的使用前述数据，亦不得以任何形式将前述数据提供给他人，不得有任何破坏青蛙视频与其客户之间业务关系的行为。 </p>

                <p>6.5 一旦您停止使用本服务，或青蛙视频基于任何原因终止您使用本服务，您必须立即删除全部从青蛙视频或本平台获得的数据（包括各种备份），且不得再以任何方式进行使用。 </p>

                <p>6.6
                    除本服务为保障数据安全而采取的各类安全技术措施之外，您应自行对因使用本服务而存储在青蛙视频服务器的各类数据等信息，采取合理、安全的技术措施，确保其安全性，并对自己的行为所引起的结果承担全部责任。 </p>

                <p><b>7、风险及免责 </b></p>

                <p>7.1 您理解并同意：为了向您提供有效的服务，本服务会利用您终端设备的处理器和带宽等资源。本服务使用过程中可能产生数据流量的费用，您需自行向运营商了解相关资费信息，并自行承担相关费用。 </p>

                <p>7.2 您理解并同意：青蛙视频将按照行业标准和最佳实践采取合理措施保障您的信息安全。在使用本服务时，对于经合理最大努力后仍不可掌控的风险给您造成的损失，青蛙视频不承担相应的责任，包括但不限于： </p>

                <p>7.2.1 由于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏等不可抗拒因素可能引起的信息丢失、泄漏等损失和风险； </p>

                <p>7.2.2 用户或青蛙视频的电脑软件、系统、硬件和通信线路出现故障导致的服务终端、数据丢失以及其他的损失和风险； </p>

                <p>7.2.3 用户操作不当或通过非青蛙视频授权的方式使用本服务带来的损失和风险；</p>

                <p>7.2.4 用户发布的内容被他人转发、分享，因此等传播可能带来的风险和责任；</p>

                <p>7.2.5 由于网络信号不稳定等原因，所引起的青蛙影视区块链发布平台登录失败、资料同步不完整、页面打开速度慢等风险； </p>

                <p>7.2.6 其他青蛙视频无法控制或合理预见的情形。</p>

                <p>7.3
                    您理解并同意，您通过青蛙影视区块链发布平台发布的内容可能会被其他用户或第三方复制、转载、修改或做其他用途，脱离您的预期和控制，您应充分意识到此类风险的存在，任何您不愿被他人获知的信息都不应在青蛙影视区块链发布平台发布。如果相关行为侵犯了您的合法权益，您可以向青蛙影视区块链发布平台投诉，我们将依法进行处理。 </p>

                <p>7.4 青蛙视频依据本条款约定获得处理违法违规内容或行为的权利，该权利不构成青蛙视频的义务或承诺，青蛙视频不能保证及时发现违法违规情形或进行相应处理。 </p>

                <p>7.5 您理解并同意，因业务发展需要，青蛙视频保留单方面对本服务的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销的权利，用户需承担此风险。</p>

                <p>7.6
                    青蛙视频会根据您选择的服务类型向您提供相应的服务。您理解并同意，基于用户体验、青蛙影视区块链发布平台运营安全、平台规则要求及健康发展等综合因素，青蛙视频有权选择提供服务或开展合作的对象，有权决定功能开放、数据接口和相关数据披露的对象和范围，并有权视具体情况中止或终止向存有包括但不限于以下情形的用户提供本服务： </p>

                <p>7.6.1 违反法律法规或本条款规定的； </p>

                <p>7.6.2 影响使用者体验的；</p>

                <p>7.6.3 存在安全隐患的； </p>

                <p>7.6.4 与青蛙影视区块链发布平台已有主要功能或功能组件相似、相同，或可实现上述功能或功能组件的主要效用的； </p>

                <p>7.6.7 违背青蛙影视区块链发布平台运营原则，或不符合青蛙视频其他管理要求的。</p>

                <p>7.7
                    您理解并同意：在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、风暴等以及社会事件如战争、动乱、政府行为等。 </p>

                <p><b>8、【知识产权声明】 </b></p>

                <p>8.1 青蛙视频在本服务中提供的影视资料是获得相应授权的，但授权范围、授权时间会根据具体的情况进行调整，具体以本平台公示为准。 </p>

                <p><b>8.2 您承诺在使用本服务时发布上传的节目已获合法授权，若因您上传的节目侵犯他人知识产权的，您将承担相应法律和经济责任。 </b></p>

                <p>8.3
                    青蛙影视区块链发布平台是一个获取、分享及传播信息的平台，为向所有用户提供更优质的服务，青蛙视频可能会对本平台账号的昵称、头像等公开非保密内容在法律允许的范围内进行使用。您知悉、理解并同意，为促进您所发布、传播内容的更好传播，青蛙视频将在其相关或合作网站、应用程序、产品或终端设备中与您所发布、传播的内容一并展示您的本平台账号的昵称、头像等公开非保密内容。 </p>

                <p>8.4 青蛙视频在本服务中所使用的青蛙影视区块链发布平台 LOGO 及其商业标识，著作权或商标权归青蛙视频所有。</p>

                <p>8.5 上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经青蛙视频、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。 </p>

                <p>8.6
                    您理解并同意，您向任何第三人分享、转发、复制青蛙影视区块链发布平台信息内容的行为，均应遵守青蛙影视区块链发布平台为此制定的规范和标准，包括但不限于展示方式应为该信息或内容的原链接、确保附属于该信息或内容的功能可正常使用等。 </p>

                <p>8.7
                    任何未经青蛙视频书面同意及权利人许可的非法获取行为，均属违法侵权行为。您确认并同意，为及时、有效地保障您基于本服务的合法权益，您授权青蛙视频在发现您的合法权益（包括但不限于信息网络传播权、著作权等）可能受到侵害时，有权代为向涉嫌侵权的第三人采取法律手段进行维权，授权采取的法律手段包括但不限于发函警告、提起诉讼、申请仲裁、移送侦查机关处理等。</p>

                <p>8.8
                    您仅拥有本条款约定合法使用本服务或青蛙视频提供相关技术接口的权利，与本服务相关的技术接口、相关的著作权、专利权等相关全部权利归青蛙视频所有。未经青蛙视频许可，您不得违约或违法使用，不得向任何单位或个人出售、转让、授权青蛙视频的代码、技术接口及开发工具等。 </p>

                <p><b>9、法律责任 </b></p>

                <p>9.1
                    如果青蛙视频发现或收到他人投诉用户违反本条款约定的，青蛙视频有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、删除部分或全部订阅用户、限制或禁止使用部分或全部功能、帐号封禁直至注销的处罚，并公告处理结果。青蛙视频也有权依照本条款及专项规则的规定，拒绝再向该主体提供服务。如果您发现任何人违反本条款规定或以其他不当的方式使用青蛙影视区块链发布平台服务，请立即向青蛙影视区块链发布平台投诉，我们将依法进行处理。 </p>

                <p>9.2
                    您理解并同意，青蛙视频有权依合理判断对违反有关法律法规或本条款规定的行为进行处罚，对违法违规的任何人士采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。 </p>

                <p>9.3 您理解并同意，因您违反本条款、专项规则或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；青蛙视频因此遭受损失的，您也应当一并赔偿。 </p>

                <p>9.4
                    您理解并同意，为及时保障用户合法权益不受侵害，若您的青蛙影视区块链发布平台账号存在权益纠纷，青蛙视频有权根据情况，采取包括但不限于暂停、冻结该账号的部分或全部功能等保护措施，直至有权机关作出生效裁判或用户在不违反本条款的前提下协商一致，由此所产生的一切法律责任均与青蛙视频无关。 </p>

                <p>9.5
                    为保障平台管理措施的有效执行，避免利用注册不同帐号、设立或使用不同主体进行注册等方式绕开、避开或对抗平台的管理，对于同一主体，或其存在关联关系的主体（包括但不限于存在母子公司、总分公司关系，直接或间接的控股关系，实际控制人、董事、监事、高级管理人员与该主体存在直接或者间接控制的关系，协议控制
                    VIE
                    结构，或从证券市场披露、其官方宣传等能够确认存在关联的关系等）单独或合计多次存在违法、违规或违反本服务相关的协议规则的行为，青蛙视频有权不再提供申诉渠道，亦有权拒绝再为该等主体提供服务。 </p>

                <p><b>10、通知 </b></p>

                <p>10.1
                    您使用本服务即视为您已阅读并同意受本条款的约束。青蛙视频有权在必要时修改本条款条款。青蛙视频可能会以包括但不限于在相关服务页面展示、网页公告、网页提示、电子邮箱、手机短信、常规的信件传送、您注册使用本服务的帐号管理系统内发送站内信等方式中的一种或多种，向您送达关于本服务的各种规则、通知、提示等信息，您可以在相关服务页面查阅最新版本的条款。本条款条款变更后，青蛙视频将通知您修改后的协议文本。 </p>

                <p>10.2
                    您同意，任何由于电子邮箱、手机号码、通讯地址等信息错误或其他不可归咎于青蛙视频的原因，导致您未收到相关规则、通知、提示等信息的，均不影响该等信息对您所产生的法律效力，并您应受其约束，由此产生的一切后果及责任由您自行承担。</p>

                <p>10.3 您也同意青蛙视频可以向您的电子邮件、手机号码等发送可能与本服务不相关的其他各类信息包括但限于商业广告等。 </p>

                <p>10.4 您清楚知悉并同意，若您有任何事项需要通知青蛙视频的，均应当按照本服务对外正式公布的联系方式或渠道系统进行提交，否则，青蛙视频将无法收到您的通知。 </p>

                <p><b>11、其他</b></p>

                <p><b>11.1 本条款的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</b></p>

                <p><b>11.2 若您和青蛙视频之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本条款签订地有管辖权的人民法院管辖。 </b></p>

                <p>11.3 本条款所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本条款涵义解释的依据。 </p>

                <p>11.4 本条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>

                <p style="padding-top: .4rem;">青蛙视频有限公司</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "problem"
}
</script>

<style scoped>
.headerBack {
    height: 2.5rem;
    width: 100%;
}

.headerBack > img {
    width: 100%;
    height: 100%;
}

.title {
    font-size: .12rem;
    font-weight: 600;
    color: #000000;
    height: .23rem;
    position: relative;
    margin-top: .3rem;
}

.title:after {
    content: '';
    width: .6rem;
    height: .03rem;
    background: #00c2af;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: .015rem;
}

.content {
    width: 7rem;
    margin: 0 auto;
}

.textStyle {
    text-indent: .1rem;
    padding-left: .1rem;
}

.contentText {
    padding-top: .18rem;
    font-size: .1rem;
    padding-bottom: .4rem;
}
</style>