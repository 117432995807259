<template>
    <div class="box">
        <div class="headerBack">
            <img src="https://q-res.oss-cn-beijing.aliyuncs.com/qingwa-index/%E8%81%94%E7%B3%BB%E6%88%91%E4%BB%AC.png"
                 alt=""/>
        </div>
        <div class="content">
            <div class="title">
                海南青蛙传媒科技有限公司
            </div>
            <div class="minTitle"></div>
            <div class="contentText">
                <p>海南省海口市美兰区海府街道海秀东路6号望海国际广场一期20层2003房</p>

                <p>电子邮箱：kefu@qingwashipin.com</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "problem"
}
</script>

<style scoped>
.headerBack {
    height: 3rem;
    width: 100%;
}

.headerBack > img {
    width: 100%;
    height: 100%;
}

.title {
    font-size: .12rem;
    font-weight: 600;
    color: #000000;
    height: .23rem;
    position: relative;
    margin-top: .3rem;
}

.title:after {
    content: '';
    width: .6rem;
    height: .03rem;
    background: #00c2af;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: .015rem;
}

.content {
    width: 7rem;
    margin: 0 auto;
}

.textStyle {
    text-indent: .1rem;
    padding-left: .1rem;
}

.contentText {
    padding-top: .18rem;
    font-size: .1rem;
    padding-bottom: .2rem;
}

.minTitle {
    font-size: .08rem;
    padding-top: .06rem;
}
</style>