<template>
    <div class="box">
        <div class="headerBack">
            <img src="https://q-res.oss-cn-beijing.aliyuncs.com/qingwa-index/%E7%BB%93%E7%AE%97%E8%AF%B4%E6%98%8E.png"
                 alt=""/>
        </div>
        <div class="content">
            <div class="title">
                结算说明
            </div>
            <div class="contentText">
                <p><b>结算方式：</b></p>

                <p>月结，即每月1日0时0分0秒至最后一日23时59分59秒为完整结算时间范围。</p>

                <p><b>结算统计：</b></p>

                <p>每月1日0时0分0秒开始结算上一月账单，预计在5小时内生成结算单。</p>

                <p><b>付款周期：</b></p>

                <p><b>渠道方</b>：每月1日至10日为结算付款周期，11日起即为逾期（逾期会影响版权资源购买、使用及部分功能禁用）；</p>
                <p><b>版权方</b>：渠道方完成月结付款后，平台在72小时（如遇法定节假日，将会推迟）内完成对版权方对公付款，同时在结算中心月结单状态更新，请随时跟踪月结单状态。</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "problem"
}
</script>

<style scoped>
.headerBack {
    height: 2.5rem;
    width: 100%;
}

.headerBack > img {
    width: 100%;
    height: 100%;
}

.title {
    font-size: .12rem;
    font-weight: 600;
    color: #000000;
    height: .23rem;
    position: relative;
    margin-top: .3rem;
}

.title:after {
    content: '';
    width: .6rem;
    height: .03rem;
    background: #00c2af;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: .015rem;
}

.content {
    width: 7rem;
    margin: 0 auto;
}

.textStyle {
    text-indent: .1rem;
    padding-left: .1rem;
}

.contentText {
    padding-top: .18rem;
    font-size: .1rem;
    padding-bottom: .2rem;
}
</style>