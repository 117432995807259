<template>
    <div class="box">
        <div class="headerBack">
            <img src="https://q-res.oss-cn-beijing.aliyuncs.com/qingwa-index/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.png"
                 alt=""/>
        </div>
        <div class="content">
            <div class="title">
                隐私政策
            </div>
            <div class="contentText">
                <p>
                    欢迎阅读青蛙视频影视区块链发行平台（以下简称青蛙视频）的隐私权保护规则。青蛙视频十分重视对平台用户隐私的保护，保护用户隐私是青蛙视频的一项基本政策。本隐私声明解释了这些情况下的数据收集和使用情况。本隐私政策适用于青蛙视频的所有相关服务，随着青蛙视频服务的变动或扩展，隐私声明的内容可由青蛙视频随时更新，且毋须另行通知。更新后的隐私声明一旦在网页上公布即有效代替原来的隐私声明。</p>

                <p><b>1、我们收集哪些用户信息及如何使用收集的信息</b></p>

                <p>(1.1)
                    一般情况下，用户可以匿名访问青蛙视频网站进行浏览、搜索等，您不需要注册成为我们的会员及提供用户信息。当您需要入驻青蛙视频时，我们可能会收集这些信息，否则您将无法正常使用我们的产品或服务：您的机构名称、机构证件代码、中国境内手机号码、Email地址、操作日志、设备信息、IP地址、您所在的省份和地市、<span
                        style="color: red;">以及相关认证材料。</span></p>

                <p>(1.2) 具体而言，我们可能收集的信息有如下种类:</p>

                <p>(1.2.1) <span style="color: red;">您的账单名在您的入驻认证后发送给您</span>，是您在本平台的标识，不可修改；</p>

                <p>
                    (1.2.2)手机号码用于交易订单支付时验证码推送；在经您同意的前提下向您发送业务资源预警等必要的通知信息；为客服或销售人员与您进行沟通，如客户调查、服务调查、咨询投诉等；用于接受本平台的商务推广信息，但会设置关闭/退订通道；</p>

                <p>(1.2.3)
                    您的Email地址，与您的手机号码用途类似；在经您同意的前提下向您发送业务资源预警等必要的通知信息；为客服或销售人员与您进行沟通，如客户调查、服务调查、咨询投诉等；用于接受本平台的商务推广信息，但会设置关闭/退订通道；可能向您发送合同文本或其他文书；</p>

                <p>(1.2.4) 操作日志、设备信息、IP地址，用于完善我们的服务，如核对业务信息、优化产品和服务；</p>

                <p>(1.2.5) 您所在的省份和地市，不包含您的具体定位，以方便客服或销售根据您所在城市有针对性的给您提供更好的服务，或用于对我们的客户群体分布进行内部分析；</p>

                <p>(1.2.6)
                    实名认证时收集的各类信息，是为了合法合规的向您提供产品或服务，明确合同主体和资质条件。我们仅用于跟您进行业务合作、为您提供技术服务或改进服务，不会在未经您的明确同意前提下向第三方提供；</p>

                <p>(1.2.7)
                    如果您和我们需要因业务往来提供您的银行账号，我们会告知您提供银行账户，同时您的账户需要对应您的开票信息。您可能需要在青蛙视频平台生成您的会员专属账号，该账户中的财产信息我们需予以记录和保留（仅限于用户在青蛙视频平台上充值和消费的财产）。有时您向您的会员专属账号充值时，我们可能需要您提供银行等转账信息，如交易流水号和付款凭证，用以证明您的充值情况。</p>

                <p>(1.2.8) 您与我们的业务往来数据，我们仅保留对账期间的脱敏日志用于财务管理，不会对数据进行储存，也不会对第三方提供。</p>

                <p>(1.3)
                    若您涉及到未成年人等无民事行为能力人或者限制民事行为能力人的个人信息的，您需在操作前征得其监护人同意，但是法律、行政法规另有规定的除外。如果我们识别出您是儿童用户的，我们将通知您的监护人并要求您的监护人同意儿童个人信息收集使用规则。</p>

                <p>(1.4)
                    我们可能以用户信息统计数据为基础，设计、开发、推广全新的产品及服务；我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包含您的任何身份识别信息。</p>

                <p>(1.5)
                    为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，确保操作环境安全与识别账号异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或青蛙视频平台相关协议、规则的情况，我们可能使用您的会员信息、并整合设备信息、有关网络日志以及我们关联公司、合作伙伴合法分享的信息，来判断您账户及交易风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施。</p>

                <p>(1.6) 部分产品或服务可能需要上述信息以外的其他信息，若有需要收集，我们会另外明确告知您并征得您的同意。</p>

                <p><b>2、我们如何保护、存储、删除您的用户信息</b></p>

                <p>(2.1) 青蛙视频严格保护您个人信息的安全。我们使用各种安全技术和程序来保护您的个人信息。</p>

                <p>(2.2) 我们按照相关法律法规允许存储的内容范围和期限进行存储，法律规定不可存储的我们不会进行存储。若法律没有明确规定的，我们按照与您签署的各类协议进行处理。</p>

                <p>(2.3) 如果您要求我们删除属于您的个人信息，请联系我们，我们会按照您的要求予以删除。在删除之前您如果需要对一些信息或记录进行保存的，您需要自行备份。</p>

                <p>(2.4) 当您决定不再使用我们的产品时，您可以申请注销账户。您可以<span style="color: red;">联系青蛙视频客服</span>，我们的客服会在15个工作日内完成核查处理。</p>

                <p>您理解并接受：</p>

                <p>(2.4.1) 您注销账户的行为会给您的售后维权带来诸多不便，一旦被注销即不可恢复。因此，我们会对您的注销申请进行必要的核查，包括：</p>

                <p>a)账户内没有未完成的订单或服务；</p>

                <p>b)账户内没有余额，如有余额您可以先申请退款，如不申请退款即申请注销，我们视为您放弃余额；</p>

                <p>c)账户内没有欠款，如有欠款需先足额支付；</p>

                <p>d)账户无任何纠纷争议，包括投诉举报、实名认证主体非本人等；我们认为有必要时，需要您提供主体资质信息，以确认您有权注销账户；</p>

                <p>e)近期内没有异常登录、更改实名认证信息等敏感操作；</p>

                <p>(2.4.2)
                    您的账户注销后，我们会在系统中去除账户信息，使其保持不可被检索、访问的状态，或对其进行匿名化处理。但您知晓并接受，按照有关法律法规的规定，商品和服务信息、交易信息保存时间自交易完成之日起不少于三年；法律、行政法规另有规定的，依照其规定。</p>

                <p><b>3、我们如何共享、转让、公开披露您的用户信息</b></p>

                <p>(3.1) 青蛙视频在通常情况下未经您的明确同意不会共享、转让、公开披露您的用户信息，下列情况除外：</p>

                <p>(3.1.1) 符合法律、行政法规的明文规定或国家政策；</p>

                <p>(3.1.2) 符合青蛙视频相关服务条款、软件许可使用协议的约定；</p>

                <p>(3.1.3) 应国家公权力部门的要求或司法机关的要求；</p>

                <p>(3.1.4) 为了促成您的交易或者协助解决您的纠纷提供必要的信息；</p>

                <p>(3.1.5) 与国家安全、社会公共利益有关的情况下；</p>

                <p>(3.1.6) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>

                <p>(3.1.7) 您自行向社会公众公开的个人信息；</p>

                <p>(3.2) 在青蛙视频自身发生公司分立、合并等情况下，如涉及到用户信息转让，我们会要求新的持有您用户信息的公司、组织继续受本政策的约束。</p>

                <p>(3.3)
                    当我们的某项服务是由我们的关联公司或合作伙伴向您提供时，我们可能会与关联公司或合作伙伴共享您的某些用户信息，以提供更好的客户服务和优化用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并严格约束用途，以及会签署严格的保密协议。</p>

                <p><b>4、Cookie和同类技术的使用</b></p>

                <p>
                    您了解并同意，青蛙视频使用cookie或同类技术来使青蛙视频网站对用户更友好便捷，它可以帮您省去为使用我们的服务而重复输入注册信息和跟踪您的浏览器的状态。如果您的浏览器允许设置，您可以拒绝青蛙视频的"Cookies"
                    。</p>

                <p><b>5、您如何管理您的用户信息</b></p>

                <p>
                    您可以登陆青蛙视频官网注册、查询、修改、删除您的账户信息。建议您使用复杂密码，协助我们保证您的账号安全。您的青蛙视频账户所有操作视为您本人或者征得您本人/本单位的充分授权而操作，尤其是对于购买、消费、承担责任和义务的操作行为，其法律后果需由您承担。若您的账户被盗号或发生异常，请及时与我们联系作出相应处理。</p>

                <p><b>6、免责说明</b></p>

                <p>就下列相关事宜的发生，青蛙视频不承担任何法律责任:</p>

                <p>(6.1) 由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏，或其他非因青蛙视频原因导致的个人信息的泄漏；</p>

                <p>(6.2) 青蛙视频根据法律法规或政府相关政策的要求、配合国家有权机关的调查取证提供您的个人信息；</p>

                <p>(6.3) 任何第三方根据青蛙视频各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷；</p>

                <p>(6.4) 任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性网站关闭；</p>

                <p>(6.5) 因不可抗力导致的任何后果；</p>

                <p>(6.6) 青蛙视频在各服务条款及声明中列明的使用方式或免责情形。</p>

                <p><b>7、如何联系我们</b></p>

                <p>若您有任何疑问或需要，可联系我们。联系方式详见青蛙视频官方网站于网页右下端标注的联系咨询方式。</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "problem"
}
</script>

<style scoped>
.headerBack {
    height: 2.5rem;
    width: 100%;
}

.headerBack > img {
    width: 100%;
    height: 100%;
}

.title {
    font-size: .12rem;
    font-weight: 600;
    color: #000000;
    height: .23rem;
    position: relative;
    margin-top: .3rem;
}

.title:after {
    content: '';
    width: .6rem;
    height: .03rem;
    background: #00c2af;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: .015rem;
}

.content {
    width: 7rem;
    margin: 0 auto;
}

.textStyle {
    text-indent: .1rem;
    padding-left: .1rem;
}

.contentText {
    padding-top: .18rem;
    font-size: .1rem;
    padding-bottom: .4rem;
}
</style>